@import "../../styles/helpers";
@import "../../styles/breakpoints";

.header {
  &__wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;    
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 35%
    );
  }

  &__logo-wrapper {
    width: calc(60% - 20px);
    padding: 20px 10px;
    text-transform: lowercase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include hover {
      text-decoration: none;
    }
  }

  &__nav-wrapper {
    width: 40vw;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 15px;
  }

  &__nav-inner-left {
    flex-grow: 1;
    position: relative;
    padding-right: 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__nav-inner-right {
    border-left: 1px solid #000;
    padding: 0 10px 0 20px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 24px;
  }

  &__nav-item {
    margin: 5px 10px 5px 0;
    &.disabled {
      text-decoration: underline;
      pointer-events: none;
    }
  }
  &__nav-item-back {
    transition: opacity 0.2s ease-in-out;
    margin: 5px 10px 5px 0;
    display: none;
    position: absolute;
    top: 0;
    &.active {
      display: block;
    }
  }
  &__nav-items-wrapper {
    transition: opacity 0.2s ease-in-out;
    flex-wrap: wrap;
    flex-grow: 1;
    &.disabled {
      display: none;
    }
  }

}

@include mobile{

  .header{

    &__wrapper {  
      background-color: #FFF;
    }

    &__logo-wrapper {
      width: calc(100% - 20px);
      padding: 10px;
    }

    &__nav-wrapper {
      width: calc(100% - 20px);
      position: static;
      display: block;
      margin: 5px 10px 0 10px;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      text-align: center;
    }

    &__nav-inner-left {
      padding: 5px 0 3px 0;
      margin: 0;
      display: block;
      border-bottom: 1px solid #000;
      min-height: 14px;
    }
    &__nav-inner-right {
      border-left: none;
      padding: 5px 0 3px 0;
      margin-bottom: 0;
      display: block;
      min-height: auto;
    }
    &__nav-item {
      margin:0 10px;
    }

  }
}