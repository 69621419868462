@import "../styles/helpers";
@import "../styles/breakpoints";

.home {
  &__entries-wrapper {
    padding: calc(7vw + 40px) 20px 50px 0;
    height: calc(var(--app-height) - (7vw + 40px) - 50px);
    overflow-y: auto;
  }

  &__entry-item {
    display: block;
    margin: 0 0 10px 10px;
    padding-left: 0;
    border-left: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    @include hover {
      padding-left: 30px;
      border-left: 1px solid #000;
      text-decoration: none;
    }
    h1 {
      pointer-events: none;
    }
    &.active {
      padding-left: 30px;
      border-left: 1px solid #000;
      pointer-events: none;
    }
    &.filtered-out {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}

@include mobile {
  .home {
    &__entries-wrapper {
      margin-top: 0;
      height: calc(var(--app-height) - 12vw - 162px);
      padding-top: calc(12vw + 112px);
    }
    &__entry-item {
      display: block;
      margin: 0 0 20px 10px;
      padding-left: 0;
      &.active {
        padding-left: 0;
        border-left: none;
      }
    }
  }
}
