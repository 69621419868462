@import "../styles/helpers";

.information{

    &__left-wrapper{
        background-color: #FFF;
        width: calc(60% - 40px);
        padding: 10vw 20px 40px 10px ;
        height: calc(var(--app-height) - 10vw);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        overflow: auto;
    }

    &__section-wrapper{
        margin-bottom: 30px;
    }

    &__section-hover-wrapper{
        transition: all 0.2s ease-in-out;
        margin-bottom: 30px;
        @include hover{
            padding-left: 30px;
            border-left: 1px solid #000;
        }
        a:hover{
            text-decoration: none;
        }
    }

    &__social-link{
        display: block;
        margin: 5px 0 5px 5px;
    }

    &__text{
        text-transform: none;
    }
}