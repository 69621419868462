.marquee{

    &__wrapper{
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 200;
        display: block;
        background-color: #0384e5;
        padding: 5px 0 2px 0;
        color: #d7cdcc;
    }
}