@font-face {
  font-family: 'HL';
  src: url('../assets/HL.eot');
  src: url('../assets/HL.eot?#iefix') format('embedded-opentype'),
      url('../assets/HL.woff2') format('woff2'),
      url('../assets/HL.woff') format('woff'),
      url('../assets/HL.ttf') format('truetype'),
      url('../assets/HL.svg#HL') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OPN archNB';
  src: url('../assets/OPNarchNB-Light.eot');
  src: url('../assets/OPNarchNB-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/OPNarchNB-Light.woff2') format('woff2'),
      url('../assets/OPNarchNB-Light.woff') format('woff'),
      url('../assets/OPNarchNB-Light.ttf') format('truetype'),
      url('../assets/OPNarchNB-Light.svg#OPNarchNB-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Session';
  src: url('../assets/Session-Condensed.eot');
  src: url('../assets/Session-Condensed.eot?#iefix') format('embedded-opentype'),
      url('../assets/Session-Condensed.woff2') format('woff2'),
      url('../assets/Session-Condensed.woff') format('woff'),
      url('../assets/Session-Condensed.ttf') format('truetype'),
      url('../assets/Session-Condensed.svg#Session-Condensed') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: "HL", sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

h1{
  font-family: "Session", serif;
  font-size: 7vw;
  line-height: 6vw;
}

h2{
  font-family: "OPN archNB", sans-serif;
  font-size: 12px;
  line-height: 14px;
}

a{
  color: inherit;
  text-decoration: none;
  @include hover{
    text-decoration: underline;
  }
}

.splash__title,
.splash__enter{
  font-family: "Session", serif;
  font-size: 7vw;
  line-height: 6vw;
  @include hover{
    text-decoration: none;
  }
}

.alt-font{
  font-family: "OPN archNB", sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

@include mobile{
  h1{
    font-size: 13vw;
    line-height: 12vw;
  }
}