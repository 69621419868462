@import "../../styles/breakpoints";

.splash {
  &__outer-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: calc(var(--app-height) - 40px);
    padding: 20px;
    // display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background-color: #D7CDCC;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    display: none;
    transition: opacity 0.4s ease-in-out;
    overflow-y: auto;
    // background-image: url("../../assets/opn.svg");
    // background-position: center;
    // background-size: contain;
    // background-repeat: repeat-y;
    // background: rgb(132, 136, 163);
    // background: linear-gradient(
    //   0deg,
    //   rgba(132, 136, 163, 1) 0%,
    //   rgba(110, 97, 123, 1) 100%
    // );
    background-color: #B9B0B5;
    &.active {
      opacity: 1;
      display: flex;
      pointer-events: all;
    }
  }

  &__title,
  &__enter {
    text-align: center;
  }

  &__content {
    width: 100%;
    height: auto;
    max-height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__content-half {
    // height: 100%;
    height: 60vh;
    min-width: calc(50% - 40px);
    max-height: calc(100% - 40px);
    flex-grow: 1;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .embed-container {
      padding-bottom: 0;
      height: 100%;
      aspect-ratio: 9/16;
    }
  }

  &__media {
    display: block;
    max-height: 50vh;
    margin: 0 auto;
  }
}

@include mobile {
  .splash {
    &__outer-wrapper {
      width: calc(100% - 40px);
      height: calc(var(--app-height) - 80px);
      padding: 40px 20px;
    }

    &__content {
      width: 100%;
      height: auto;
      flex-direction: column;
    }

    &__content-half {
      height: 100%;
      width: 100%;
      padding: 20px 0;
    }
  }
}
