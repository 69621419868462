*::selection {
  background: #0384e5;
  color: #d7cdcc;

}
*::-moz-selection {
	background: #0384e5;
  color: #d7cdcc;

}

*::-webkit-scrollbar {
  width: 0;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border: none;
}

html,
body {
  width: 100%;
  height: var(--app-height);
  overflow: hidden;
}

.loading {
  width: 15px;
  height: 15px;
  margin: 30px 15px 15px 15px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  animation: pulse 0.5s linear infinite;
}

.content {
  &__wrapper {
    width: calc(40% - 20px);
    height: calc(var(--app-height) - 80px);
    background-color: #262528;
    margin-top: 50px;
    padding: 0 10px 30px 10px;
    position: fixed;
    top: 0;
    right: -50%;
    transition: right 0.4s ease-in-out;
    box-shadow: -5px 25px 10px rgba($color: #000000, $alpha: 0.6);
    color: #fff;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 4;
    &.active {
      right: 0;
    }
  }

  &__title {
    padding: 15px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.5px solid #fff;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    background-color: #262528;

  }

  &__media-wrapper {
    width: 100%;
    margin-bottom: 10px;
    max-height: 50vh;
  }

  &__media {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 50vh;
    height: auto;
    width: auto;
  }

  &__text-wrapper {
    border-top: 0.5px solid #fff;
    border-bottom: 0.5px solid #fff;
    margin-bottom: 10px;
    padding-top: 10px;
    a {
      text-decoration: underline;
    }
    p {
      margin-bottom: 10px;
    }
    strong {
      font-weight: bold;
    }
    em {
      font-style: italic;
    }
    &.external{
      a{
        display: block;
        margin-bottom: 10px;
        text-decoration: none;
      }
    }
  }
}


@include mobile{
  .content {
    &__wrapper {
      width: calc(100% - 20px);
      height: calc(var(--app-height) - 12vw - 92px);
      margin-top: calc(12vw + 71px);
      right: -110%;
    }
  }

}

button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  @include hover {
    text-decoration: underline;
  }
}
